namespace Site
{
    let siteConfiguration:
        {
            application: string,
            controller: string,
            action: string,
        };

    export interface RequestOptions
    {
        onSuccess?: (result: any) => void,
        onError?: (request: JQueryXHR, textStatus: string, errorThrown: string, settings: JQueryAjaxSettings) => void,
        action?: string,
        controller?: string,
        populate?: Page.GetJQueryOptions,
        url?: string,
        data?: any,
        form?: Page.GetJQueryOptions | JQuery,
        method?: RequestMethod,
    }

    export enum RequestMethod
    {
        Post = "POST",
        Get = "GET",
    }

    export const get = (options: RequestOptions): JQueryXHR =>
    {
        options.method = options.method || RequestMethod.Get;
        return request(options);
    }

    export const post = (options: RequestOptions): JQueryXHR =>
    {
        options.method = options.method || RequestMethod.Post;
        return request(options);
    }

    export const formPost = (options: RequestOptions): void =>
    {
        const encodingTypeMultipartFormData = "multipart/form-data";
        options.method = options.method || RequestMethod.Post;
        setupRequestOptions(options);

        let $form: JQuery;

        if (options.form)
        {
            $form = getJQuery(options.form);
            $form.attr("method", options.method);
            $form.attr("action", options.url);
            $form.attr("enctype", encodingTypeMultipartFormData);
            $form.submit();
        }
        else
        {
            $form = createForm();
            $form.submit();
            $form.remove();
        }

        function createForm(): JQuery
        {
            let $newForm = $('<form>', { 'method': options.method, 'action': options.url, 'enctype': encodingTypeMultipartFormData }).hide();
            if (options.data instanceof FormData)
            {
                (<FormData>options.data).forEach((value, key) => $newForm.append($('<input>', { 'type': 'hidden', 'name': key, 'value': value })));
            }
            else
            {
                $.each(options.data, (key, value) => $newForm.append($('<input>', { 'type': 'hidden', 'name': key, 'value': value })));
            }
            $('body').append($newForm);

            return $newForm;
        }
    }

    export const request = (options: RequestOptions): JQueryXHR =>
    {
        setupRequestOptions(options);

        let processData = true;

        if (options.method == RequestMethod.Post && options.data != null)
        {
            if (!(options.data instanceof FormData))
            {
                let formData = new FormData();
                for (var key in options.data)
                {
                    let value = options.data[key];
                    if (value == null)
                    {
                        //do not append null values
                    }
                    else if (Array.isArray(value))
                    {
                        value.forEach((arrayValue) => { formData.append(key, arrayValue); })
                    }
                    else
                    {
                        formData.append(key, value);
                    }
                }
                options.data = formData;
            }
            processData = false;
        }

        const settings: JQueryAjaxSettings = {
            type: options.method,
            url: options.url,
            cache: false,
            contentType: false,
            processData: processData,
            success: options.onSuccess,
            data: options.data,
        }

        if (options.onError)
        {
            const onError = options.onError;
            settings.error = (request: JQueryXHR, textStatus: string, errorThrown: string) => onError(request, textStatus, errorThrown, settings);
        }

        let request: JQueryXHR = $.ajax(settings);

        return request;
    }

    export const getHost = (): string => window.location.protocol + "//" + window.location.host + siteConfiguration.application;

    export const ajaxError = (request: JQueryXHR, textStatus: string, errorThrown: string, settings: JQueryAjaxSettings): void =>
    {
        enum httpStatusCodes
        {
            Unknown = 0,
            PageNotFound = 404,
            ServerError = 500,
            NoInternet = 502
        }

        if (request.statusText != 'abort')
        {
            switch (request.status)
            {
                case httpStatusCodes.PageNotFound:
                    {
                        Notifications.showAlert({ header: "Invalid Action", message: `There was an error performing the action:${settings.url}, please contact support` });
                        break;
                    }
                case httpStatusCodes.ServerError:
                    {
                        Notifications.showAlert({ header: "Server Error", message: `There was an error on the server, please contact support.<br/>Error message:${request.responseText}` });
                        break;
                    }
                case httpStatusCodes.NoInternet:
                    {
                        if (settings)
                        {
                            Notifications.showAlert({ header: "Connection Lost", message: 'We tried to perform the requested action and received error 502:No Internet, this may be temporary and it may work if you try again.<br/>Try again?.' });
                        }
                        else
                        {
                            Notifications.showAlert({ header: "Connection Lost", message: 'We tried to perform the requested action and received error 502:No Internet, this may be temporary and it may work if you try again.' });
                        }
                        break;
                    }
                case httpStatusCodes.Unknown:
                default:
                    {
                        Notifications.showAlert({ header: "Unknown Error", message: 'There was an unknown error on the server, please contact support' });
                        break;
                    }
            }
        }
    }

    const setupRequestOptions = (options: RequestOptions): void =>
    {
        options.method = options.method || RequestMethod.Get;
        options.controller = options.controller || siteConfiguration.controller;
        options.onError = options.onError || ajaxError;

        if (options.populate != null)
        {
            const onSuccessFunction = options.onSuccess;
            const populateOptions = options.populate;

            options.onSuccess = (result) =>
            {
                getJQuery(populateOptions).html(result);
                if (onSuccessFunction != null)
                {
                    onSuccessFunction(result);
                }
            };
        }

        if (options.data == null && options.form != null)
        {
            options.data = new FormData(Page.getForm(options.form));
        }

        options.url = options.url || `${getHost()}/${options.controller}/${options.action}`;
    }

    export const load = () =>
    {
        siteConfiguration = JSON.parse($("[data-container-for='siteConfiguration']").html());

        if (new URL(window.location.href).searchParams.has('testAjaxError'))
        {
            get({ action: "TestAjaxError" });
        }
    }
}

Site.load();